<template>
  <div class="d-flex jc-c ai-c full-height">
    <div class="text-center">
      <div class="medium-text">PAGE NOT FOUND</div>
      <div class="lg-text green-text">404</div>
      <router-link to="/" class="btn btn-full btn-lg btn-orange-gradient"
        >GO HOME</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>

<style scoped lang="scss">
.full-height {
  width: 100%;
  height: 100vh;
}
.jc-c {
  justify-content: center;
}
.ai-c {
  align-items: center;
}

.medium-text {
  font-size: 32px;
}
.lg-text {
  font-size: 54px;
  font-weight: 700;
}
</style>
