<template>
  <div class="right-sidebar" @click.self="closeSignUpModal">
    <div class="sign-up">
      <div class="sign-up--wrap">
        <div class="sign-up--title">Sign Up <span>FREE</span></div>
        <form @submit.prevent="onSubmit" novalidate="true">
          <div class="flex-grow">
            <AppInput
              name="name"
              placeholder="John"
              label="First name:"
              required
              prepend-icon="icon-user"
              v-model="form.name"
              @input="validateForm"
              @blur="validateForm"
              :class="{ 'error-field': form.errorNameStatus }"
            />
            <div class="error" v-if="form.errorName">{{ form.errorName }}</div>

            <AppInput
              name="email"
              placeholder="example@email.com"
              label="E-mail:"
              required
              prepend-icon="icon-envelope"
              type="email"
              v-model="form.email"
              @input="validateForm"
              @blur="validateForm"
              :class="{ 'error-field': form.errorEmailStatus }"
            />

            <div class="error" v-if="form.errorEmail">
              {{ form.errorEmail }}
            </div>

            <!-- <div class="form-check">
              <label
                ><input
                  type="checkbox"
                  name="sign_agree"
                  class="checkbox"
                  required
                  checked
                />
                <span>I agree with License agreement</span></label
              >
            </div> -->

            <div class="checkbox-wrapp">
              <div
                class="checkbox-box"
                @click="form.checkboxAgreement = !form.checkboxAgreement"
              >
                <input
                  type="checkbox"
                  name="sign_agree"
                  class="checkbox-field"
                  :class="{ checked: form.checkboxAgreement }"
                  :checked="form.checkboxAgreementModal"
                  v-model="form.checkboxAgreement"
                />
                <span class="checkbox-text">
                  I agree with License agreement
                </span>
                <span class="checkbox-checkmark"></span>
              </div>

              <div class="error" v-if="!form.checkboxAgreement">
                {{ form.errorCheckbox }}
              </div>
            </div>

            <!-- <div class="error" v-if="!form.allError">
              {{ form.allError }}
            </div> -->
          </div>
          <div class="form-buttons">
            <button
              class="btn btn-full btn-lg btn-orange-gradient"
              type="submit"
              :disabled="
                form.errorEmailStatus ||
                  form.errorEmailStatus ||
                  !form.checkboxAgreement
              "
              @click="validateForm"
            >
              Create an account
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="copyright">
      2008-2021 © All rights reserved
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/AppInput";
import signUpModalMixin from "@/signUpModalMixin";
import { mapState } from "vuex";

import axios from "axios";

export default {
  name: "AppSignUp",
  mixins: [signUpModalMixin],
  data() {
    return {
      form: {
        name: "",
        email: "",
        checkboxAgreement: true,
        errorCheckbox: "This field is required.",

        allError: null,
        validSubmit: false,

        errorNameStatus: false,
        errorEmailStatus: false
      }
    };
  },
  components: { AppInput },
  computed: {
    ...mapState({
      ip: state => state.ip
    })
  },
  methods: {
    validateForm: function() {
      if (!this.form.name) {
        this.form.errorNameStatus = true;
        this.form.errorName = "This field is required.";
      } else if (this.form.name.length <= 2) {
        this.form.errorNameStatus = true;
        this.form.errorName = "Please enter at least 3 characters.";
      } else if (this.form.name.length >= 14) {
        this.form.errorNameStatus = true;
        this.form.errorName = "Please enter no more than 15 characters.";
      } else {
        this.form.errorNameStatus = this.form.errorName = false;
      }

      if (!this.form.email) {
        this.form.errorEmailStatus = true;
        this.form.errorEmail = "This field is required.";
      } else if (this.form.email.length <= 5) {
        this.form.errorEmailStatus = true;
        this.form.errorEmail = "Please enter at least 6 characters.";
      } else if (!this.validEmail(this.form.email)) {
        this.form.errorEmailStatus = true;
        this.form.errorEmail = "Please, enter a valid email";
      } else {
        this.form.errorEmailStatus = this.form.errorEmail = false;
      }

      // if (!this.form.allError) {
      //   this.form.allError = "This field is required.";
      // } else {
      //   this.form.errorEmailStatus = this.form.errorEmail = false;
      // }

      // !this.form.allError
      if (this.form.name && this.form.email && this.form.checkboxAgreement) {
        this.form.validSubmit = true;
      } else {
        this.form.validSubmit = false;
      }
    },

    validEmail: function(email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    onSubmit: async function() {
      if (this.form.validSubmit) {
        let formData =
          "name=" +
          this.form.name +
          "&email=" +
          this.form.email +
          "&sign_agree=" +
          this.form.checkboxAgreement;

        let ip = this.ip;

        let oSearchParams = new URL(document.location).searchParams;

        formData +=
          "&ip=" +
          ip +
          "&pid=" +
          oSearchParams.get("pid") +
          "&pid_sid=" +
          oSearchParams.get("sid");

        try {
          let oResp = await axios.post("/api/?action=registration", formData);

          if (oResp.data.error && oResp.data.error !== "") {
            alert(oResp.data.error);
          } else if (oResp.data.loc) {
            setTimeout(function() {
              document.location = oResp.data.loc;
            }, 500);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
};
</script>
