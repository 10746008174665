import { createStore } from "vuex";
import _ from "lodash";

export default createStore({
  state: {
    allUsers: [],
    ip: ""
  },
  mutations: {
    SET_ALL_USERS(state, users) {
      state.allUsers = users;
    },
    SET_USER_IP(state, ip) {
      state.ip = ip;
    }
  },
  actions: {
    getIp({ commit }) {
      fetch("https://api.ipify.org?format=json")
        .then(x => x.json())
        .then(({ ip }) => {
          commit("SET_USER_IP", ip);
        });
    },
    async fetchUsers({ state, commit }) {
      if (state.allUsers.length > 0) return state.allUsers;
      const formdata = new FormData();
      formdata.append("action", "profiles");
      // const url =
      //   process.env.NODE_ENV === "development"
      //     ? "/api/files/acounts.json"
      //     : "/api/profiles.php";

      const url = "/api/?action=get_profiles";

      const options =
        process.env.NODE_ENV === "development"
          ? {}
          : {
              method: "POST",
              body: formdata
            };
      const response = await fetch(url, options);
      const users = await response.json();

      const usersArray = Object.values(users);

      commit("SET_ALL_USERS", usersArray);
      return usersArray;
    }
  },
  getters: {
    usersRandom(state) {
      return _.shuffle(state.allUsers)
        .slice(0, 12)
        .map(user => {
          return {
            ...user,
            is_new: Math.random() < 0.5,
            online: Math.random() < 0.5
          };
        });
    },
    allUsers(state) {
      return state.allUsers;
    }
  },
  modules: {}
});
