<template>
  <div
    class="girl-card"
    @click="$router.push({ name: 'ProfileId', params: { id: user.id } })"
  >
    <div class="girl-avatar">
      <div class="card-camera">
        <img src="images/web-camera.svg" alt="web-camera" />
      </div>

      <div class="label-online" v-if="user.online">
        Online
      </div>

      <img :src="applyImgSrc(user.photo)" :alt="user.name" v-if="user.photo" />
    </div>
    <div class="girl-name">
      <span class="icon-circle" v-if="user.online"></span>
      <span>{{ user.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppProfileCard",
  methods: {
    applyImgSrc: function(_sUrl) {
      return "//loveinchat.com" + _sUrl;
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
