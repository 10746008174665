<template>
  <div class="responsive-bg">
    <div class="bg-image"></div>
  </div>
  <div class="section-girls">
    <div class="container">
      <div class="stats-block">
        <div><strong class="blue-text">6177</strong> Single Girls</div>
        <div>
          <strong class="green-text">{{ userOnlineCount }}</strong> Online Now
        </div>
      </div>
      <div class="row">
        <div class="col" v-for="user in usersRandom" :key="user.id">
          <AppProfileCard :user="user" />
          <h2>{{ user.title }}</h2>
          <p>{{ user.body }}</p>
        </div>
      </div>

      <div class="text-center">
        <a href="#0" id="seeAllGirls" @click.prevent="setSignUpModal"
          >See all girls <span class="icon-arrow"></span
        ></a>
      </div>

    </div>
  </div>
</template>

<script>
import AppProfileCard from "@/components/AppProfileCard";
import signUpModalMixin from "@/signUpModalMixin";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: { AppProfileCard },
  mixins: [signUpModalMixin],
  computed: {
    ...mapGetters({
      usersRandom: "usersRandom"
    }),
    userOnlineCount() {
      return Math.floor(Math.random() * 50) + 321;
    }
  }
};
</script>
